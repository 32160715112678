<!-- -->
<template>
    <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item replace :to="{ path: '/home' }">{{ $t('home.home') }}</el-breadcrumb-item>
            <el-breadcrumb-item v-for="item in list" :to="{ path: item.path }" :key="item.path">{{ item.title }}</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>
<script>
export default {
    name: '',
    props: {
        list: Array
    },
    components: {},
    data() {
        return {
            
        }
    },
    methods: {},
    created() {},
    mounted() {
            
    },
}
</script>
<style  lang='less'>
.breadcrumb {
    padding: 21px 0;
    .el-breadcrumb {
        font-size: 18px;
    }
}
</style>
