<template>
  <!-- :show-close="false" -->
  <el-dialog
    :visible="showLogin"
    width="600px"
    destroy-on-close
    :close-on-click-modal="false"
    class="self-dialog"
    @close="onClose"
    :modal-append-to-body="false"
    :title="$t('login.title')"
  >
    <div class="login">
      <el-form
        :model="data"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        label-position="top"
      >
        <el-form-item
          :label="$t('login.emailCode')"
          prop="email"
          class="dev-label"
        >
          <el-input
            v-model="data.email"
            class="dev-input"
            :placeholder="$t('login.emailmsg')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('login.userPass')"
          prop="password"
          class="dev-label"
        >
          <el-input
            type="password"
            class="dev-input"
            v-model="data.password"
            :placeholder="$t('login.passplacehold')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="password">
        <el-checkbox v-model="checked" round>{{
          $t("login.remerber")
        }}</el-checkbox>
        <span class="password-text" @click="onForget">{{
          $t("login.forget")
        }}</span>
      </div>
      <div class="floot">
        <el-button @click="onLogin" class="login-btn" type="primary" round>
          {{ $t("login.title") }}</el-button
        >
        <div class="floot-register" @click="onRegister">
          {{ $t("login.register") }}?
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
// import { useI18n } from "vue-i18n";
import { login, getUserInfo } from "@/api/user";
import requirtMinix from "./requirtMinix";
var Base64 = require("js-base64").Base64;
export default {
  mixins: [requirtMinix],
  data() {
    return {
      submessg: false,
      checked: false, //是否记住密码
      data: {
        email: "",
        password: "",
      },
      rules: {
        email: [
          // { message: "请输入手机号", trigger: "blur" },
          {
            required: true,
            validator: this.validateEmail,
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            validator: this.validatePass,
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    showLogin() {
      return this.$store.state.showLogin;
    },
  },
  mounted() {
    console.log("1313", this.$store.state.user.token);
    let email = localStorage.getItem("email");
    // sessionStorage.setItem("_token", "");
    // localStorage.removeItem("user");
    if (email) {
      this.data.email = localStorage.getItem("email");
      this.data.password = Base64.decode(localStorage.getItem("password")); // base64解密
      this.checked = true;
    }
  },
  methods: {
    open(val) {
      console.log("触发了哦");
      // this.submessg = true;
      this.$store.commit("onChangeLogin");
    },
    onClose() {
      this.$store.commit("onClose");
    },
    //忘记密码
    onForget() {
      console.log("我忘记");
      this.$emit("onForget");
      this.$store.commit("onClose");
    },
    //重置密码
    onRegister() {
      this.$emit("onRegister");
      console.log("我注册");
      this.$store.commit("onClose");
    },
    //登录
    onLogin() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let ret = await login(this.data);
          console.log(ret);
          if (ret.code == 200) {
            this.$store.commit("user/setToken", ret.token);
            this.onClose();
            let res = await getUserInfo().catch((err) => {
              console.log(err);
            });
            console.log(res.data);
            this.$store.commit("user/setUser", res.data);
            // this.submessg = false;
          }
          if (this.checked) {
            let password = Base64.encode(this.data.password); // base64加密
            localStorage.setItem("email", this.data.email);
            localStorage.setItem("password", password);
          } else {
            localStorage.removeItem("email");
            localStorage.removeItem("password");
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.login {
  height: 100%;

  .password {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    &-text {
      cursor: pointer;
    }
  }
  .floot {
    margin-top: 53px;
    text-align: center;
    &-register {
      color: var(--primary);
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 500;
      text-decoration: underline;
      margin-top: 30px;
    }
  }
  &-btn {
    width: 100%;
    font-size: 20px;
  }
  :deep(.el-checkbox__inner) {
    border-radius: 50%;
    top: -1px;
    width: 20px;
    height: 20px;
    &::after {
      left: 5px;
      width: 5px;
      height: 10px;
    }
  }
  :deep(.el-checkbox__label) {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
  }
}
</style>
