import store from "@/store";
export function getToken() {
  return store.state.user.token;
}
export function clearToken () {
  localStorage.removeItem("vuex")
  store.commit('user/clear')
}
// 用*代替前面位数
export function onlyFourBank (value) {
	if (value && value.length > 4) {
        let num = value.length % 4
        num = num == 0 ? 4 : num
		return `${"*".repeat(value.length - num)}${value.slice(-num)}`;
	}
	return value;
}
// 每四位隔开
export function fourNums (num) {
 	if (!num) return
    let value = num.replace(/(.{4})/g, "$1 ")
    return value.split(" ").filter(v => v.length > 0)
} 

export function getLang () {
  return localStorage.getItem("language") || "en-US"
}