import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from "vuex-persistedstate";
import user from "./modules/user";
Vue.use(Vuex);
const store = new Vuex.Store({
  modules: { user },
  state: {
    showLogin: false,
    showRegister:false,
  },
  mutations: {
    onChangeLogin (state) {
      state.showLogin = !state.showLogin
    },
    onChangeRegister(state){
      state.showRegister = true
    },
    onCloseRegister (state, bol = false) {
      state.showRegister = bol
    },
    onClose (state, bol = false) {
      state.showLogin = bol
    }
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],
});
export default store;
