<template>
  <div id="app">
    <div class="pc-main">
      <Header />
      <div class="pc-view">
        <router-view v-if="!$route.meta.keepAlive"></router-view>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <img
          src="@/assets/images/pcContact.png"
          class="cont-img"
          @click="onOpen"
        />
      </div>
      <Footer />
      <ContentModal ref="content" />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ContentModal from "@/components/contentModal.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    ContentModal,
  },
  mounted() {
  },
  methods: {
    onOpen() {
      console.log(this.$refs.content);
      this.$refs.content.open();
    },
  },
};
</script>
<style>
#app {
  min-width: 1200px;
}
.pc-view {
  margin-top: 80px;
  margin-bottom: 200px;
}
.cont-img {
  width: 72px;
  height: 72px;
  position: fixed;
  bottom: 30%;
  right: 20px;
  cursor: pointer;
  z-index: 100;
}
</style>
