import Vue from 'vue'
import VueI18n from 'vue-i18n'
// 自定义国际化文件
import zh_CN from './zh-CN';
import en_US from './en-US';
import ElementUI from "element-ui"
import ElementLocale  from "element-ui/lib/locale"
import zhLocale from "element-ui/lib/locale/lang/zh-CN"
import enLocale from "element-ui/lib/locale/lang/en"
import { getLang } from "@/utils/util"
Vue.use(VueI18n)
ElementLocale.i18n((key, value) => i18n.t(key, value))
const messages = {
  'zh-CN': {
    ...zh_CN,
    ...zhLocale,
  },  
  'en-US': {
    ...en_US,
    ...enLocale
  }
}
// 注册i18n实例并引入语言文件
const i18n = new VueI18n({
  fallbackLocale: "en-US", // 匹配不到时默认的语言
  locale: getLang(),
  messages, 
})
// 非 vue 文件中使用这个方法
const translate = (localeKey) => {
  const locale = getLang()
  const hasKey = i18n.te(localeKey, locale)  // 使用i18n的 te 方法来检查是否能够匹配到对应键值
  const translatedStr = i18n.t(localeKey) 
  if (hasKey) {
      return translatedStr
  }
  return localeKey
}
const elementKey = {
  "zh_CN": zhLocale,
  "en-US": enLocale,
}
function chageLange (key) {
  i18n.locale = key
  console.log("切换了语言哦")
  localStorage.setItem('language', key)
  location.reload()
}
export default i18n
export { i18n, messages, translate, chageLange }