<template>
  <div class="foot">
    <div class="foot-child">
      <div class="logo">
        <img src="@/assets/images/login/logo.png" alt="" class="logo-img">
        <div class="logo-text">Palmfun</div>
      </div>
      <div class="foot-text">
        <el-row :gutter="20">
          <el-col
            :span="6"
            v-for="(item, index) of list"
            :key="'foot' + index"
            class="col"
          >
            <div @click="onJump(item)" class="btn-text">
              {{ item.name }}
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <span class="last">{{ $t("footText") }} @{{date}}</span>
  </div>
</template>
<script>
import { helpTypeList } from "@/api/user";
import dayjs from "dayjs"
export default {
  data() {
    return {
      list: [],
      date: dayjs(new Date()).format("YYYY")
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      let res = await helpTypeList().catch((err) => {
        console.log(err);
      });
      if (res.code == 200) {
        this.list = res.data || [];
      }
      sessionStorage.setItem("rule", JSON.stringify(res.data));
    },
    onJump(val) {
      console.log(val);
      if (val.child.length !== 0) {
        console.log("挑下一级");
        sessionStorage.setItem("sec", JSON.stringify(val));
        this.$router.push({ path: "/cententSec", query: { id: val.id } });
      } else {
        console.log("detail");
        sessionStorage.setItem(
          "nav",
          JSON.stringify([{ path: "/rule", title: this.$t("foot.rule") }])
        );
        this.$router.push({
          path: "/cententDetail",
          query: { id: val.id, name: val.name },
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.foot {
  padding: 40px 0 0;
  width: 100%;
  min-height: 270px;
  background: #ffffff;
  // position: fixed;
  bottom: 0px;
  text-align: center;
  .btn-text {
    cursor: pointer;
    margin-left: 50px;
  }
  &-child {
    margin: 20px auto 10px;
    width: 1200px;
    display: flex;
    min-height: 170px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d5d5d5;
    .logo {
      width: 232px;
      padding-left: 114px;
      border-right: 1px solid #d5d5d5;
      min-height: 130px;
      &-img {
  
          width: 54px;
          height: 54px;
          border-radius: 10px;
          position: relative;
          top: 30%;
          margin-right: 169px;
        
      }
      &-text {
        width: 57px;
        height: 15px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        position: relative;
        top: 30%;
        color: var(--primary);
        left:-5px;
      }
    }
  }
  .foot-text {
  }
  .col {
    margin-bottom: 20px;
    text-align: left;
    padding-left: 30px;
  }
  .last {
    display: inline-block;
    margin-bottom: 23px;
  }
}
</style>
