<template>
  <el-dialog
    :visible="showRefister"
    width="600px"
    destroy-on-close
    @close="onClose"
    :close-on-click-modal="false"
    class="self-dialog"
    :modal-append-to-body="false"
    :title="$t('login.register')"
  >
    <div class="login">
      <el-form
        :model="data"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        label-position="top"
      >
        <el-form-item
          :label="$t('login.emailCode')"
          prop="email"
          class="dev-label"
        >
          <el-input
            v-model="data.email"
            class="dev-input"
            :placeholder="$t('login.emailmsg')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('password.code')"
          prop="code"
          class="dev-label"
        >
          <el-input
            class="dev-input none_input_icon"
            v-model="data.code"
            :placeholder="$t('login.firstcodeLabel')"
          >
            <sendBtn
              slot="suffix"
              @click="onSend"
              ref="sendbtn"
              type="0"
              :email="data.email"
            />
          </el-input>
        </el-form-item>
        <el-form-item
          :label="$t('login.passwordName')"
          prop="password"
          class="dev-label"
        >
          <el-input
            v-model="data.password"
            class="dev-input"
            :placeholder="$t('login.thirdpass')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('login.againpass')"
          prop="againpassword"
          class="dev-label"
        >
          <el-input
            class="dev-input none_input_icon"
            v-model="data.againpassword"
            :placeholder="$t('login.againpassmsg')"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item
          :label="$t('login.whatsapp')"
          prop="whatsappId"
          class="dev-label"
        >
          <el-input
            class="dev-input none_input_icon"
            v-model="data.whatsappId"
            :placeholder="$t('login.WHATSAPplace')"
          ></el-input>
        </el-form-item> -->
        <el-form-item
          :label="$t('invitation.addCode')"
          prop="addCode"
          class="dev-label"
        >
          <el-input
            class="dev-input none_input_icon"
            v-model="data.inviteCode"
            :placeholder="$t('invitation.addCodePlace')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="floot">
        <el-button
          @click="onLogin"
          class="login-btn"
          type="primary"
          round
          :loading="senLoading"
        >
          {{ $t("login.title") }}</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>
<script>
// import { useI18n } from "vue-i18n";
import { login, verificationCode, sendEmailCode, register } from "@/api/user";
import sendBtn from "@/components/sendBtn.vue";
import requirtMinix from "./requirtMinix";
var Base64 = require("js-base64").Base64;
export default {
  mixins: [requirtMinix],
  components: { sendBtn },
  data() {
    //6-12密码
    const againPassVaild = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("login.againpassmsg")));
      } else if (value !== this.data.password) {
        return callback(new Error(this.$t("login.againmsg")));
      } else {
        return callback();
      }
    };
    return {
      senLoading: false,
      newPass: "",
      sendLoading: false,
      checked: false, //是否记住密码
      data: {
        email: "",
        code: "",
        password: "",
        whatsappId: "",
        againpassword: "",
        inviteCode: "", //邀请码
      },
      rules: {
        email: [
          // { message: "请输入手机号", trigger: "blur" },
          {
            required: true,
            validator: this.validateEmail,
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            validator: this.validatePass,
            trigger: "change",
          },
        ],
        code: [
          {
            required: true,
            message: this.$t("login.codemsg"),
            trigger: "change",
          },
        ],
        againpassword: [
          {
            required: true,
            validator: againPassVaild,
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    showRefister() {
      this.data.inviteCode = sessionStorage.getItem("myCode") || "";
      return this.$store.state.showRegister;
    },
  },
  mounted() {},
  methods: {
    open(val) {
      this.$store.commit("onChangeRegister");
    },
    async onSend() {
      this.$refs.ruleForm.validateField("email", async (valid) => {
        if (!valid) {
          this.$refs.sendbtn.onSend();
        }
      });
    },
    onClose() {
      console.log(1111);
      this.$refs.ruleForm.resetFields();
      this.$store.commit("onCloseRegister");
    },
    //登录
    async onLogin() {
      console.log(this.data);
      await this.$refs.ruleForm.validate((vaild) => {
        if (vaild) {
          this.senLoading = true;
          verificationCode({ ...this.data })
            .then((res) => {
              if (res.code == 200) {
                register({ ...this.data })
                  .then((res) => {
                    if (res.code == 200) {
                      this.$message.success(this.$t("login.success"));
                      this.onClose();
                      this.$emit("success");
                    }
                    this.senLoading = false;
                  })
                  .catch((err) => {
                    this.senLoading = false;
                    console.log(err);
                  });
              } else {
                this.senLoading = false;
              }
            })
            .catch((err) => {
              this.senLoading = false;
              console.log(err);
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.login {
  height: 100%;

  .password {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    &-text {
      cursor: pointer;
    }
  }
  .floot {
    margin-top: 53px;
    text-align: center;
    &-register {
      color: var(--primary);
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 500;
      text-decoration: underline;
      margin-top: 30px;
    }
  }
  &-btn {
    width: 100%;
    font-size: 20px;
  }
  :deep(.el-checkbox__inner) {
    border-radius: 50%;
    top: -1px;
    width: 20px;
    height: 20px;
    &::after {
      left: 5px;
      width: 5px;
      height: 10px;
    }
  }

  :deep(.el-checkbox__label) {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
  }
}
</style>
