<template>
  <el-dialog
    :visible.sync="submessg"
    width="600px"
    destroy-on-close
    @close="onClose"
    class="none-dialog"
    :modal-append-to-body="false"
    :title="$t('login.forget')"
  >
    <div class="forget">
      <el-form
        :model="data"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        label-position="top"
      >
        <el-form-item :label="$t('bind.email')" prop="email" class="dev-label">
          <el-input
            v-model="data.email"
            class="dev-input"
            :placeholder="$t('login.emailmsg')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('password.new')"
          prop="password"
          class="dev-label"
        >
          <el-input
            v-model="data.password"
            class="dev-input"
            :placeholder="$t('password.newmsg')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('login.againpass')"
          prop="newPass"
          class="dev-label"
        >
          <el-input
            class="dev-input none_input_icon"
            v-model="newPass"
            :placeholder="$t('password.confirmmsg')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('bind.code')" prop="code" class="dev-label">
          <el-input
            class="dev-input none_input_icon"
            v-model="data.code"
            :placeholder="$t('bind.codemsg')"
          >
            <sendBtn
              slot="suffix"
              @click="onSend"
              ref="sendbtn"
              type="1"
              :email="data.email"
            />
          </el-input>
        </el-form-item>
      </el-form>
      <div class="floot">
        <el-button
          @click="onSubmit"
          class="forget-btn"
          type="primary"
          round
          :loading="sendLoading"
        >
          {{ $t("order.sure") }}</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>
<script>
import sendBtn from "@/components/sendBtn.vue";
import { retrievePassword, sendEmailCode } from "@/api/user";
import requirtMinix from "../requirtMinix";
export default {
  mixins: [requirtMinix],
  components: { sendBtn },
  data() {
    const loginPassAgain = (rule, value, callback) => {
      //确认登录密码
      if (!this.newPass) {
        callback(new Error(this.$t("login.againpassmsg")));
      } else if (this.newPass.length < 6 || this.newPass.length > 18) {
        callback(new Error(this.$t("login.passLength")));
      } else if (this.newPass != this.data.password) {
        callback(new Error(this.$t("password.diffMsg")));
      } else {
        callback();
      }
    };
    return {
      submessg: false,
      sendLoading: false,
      newPass: "",
      data: {
        email: "",
        password: "",
        code: "",
      },
      rules: {
        email: [
          // { message: "请输入手机号", trigger: "blur" },
          {
            required: true,
            validator: this.validateEmail,
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            validator: this.validatePass,
            trigger: "change",
          },
        ],
        code: [
          // { message: "请输入手机号", trigger: "blur" },
          {
            required: true,
            message: this.$t("login.codemsg"),
            trigger: "change",
          },
        ],
        newPass: [
          {
            required: true,
            validator: loginPassAgain,
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    open(val) {
      this.submessg = true;
    },
    async onSend() {
      this.$refs.ruleForm.validateField("email", async (valid) => {
        if (!valid) {
          this.$refs.sendbtn.onSend();
        }
      });
    },
    onClose() {
      this.$refs.ruleForm.resetFields();
      this.againpass = "";
      this.submessg = false;
    },
    async onSubmit() {
      console.log(333);
      await this.$refs.ruleForm.validate();
      let ret = await retrievePassword(this.data).catch((err) => {
        this.$message.error(err);
        this.subLoading = false;
      });
      if (ret.code != 200) {
        this.subLoading = false;
        return;
      } else {
        this.subLoading = false;
        this.onClose();
        this.$message.success(this.$t("password.successMsg"));
        this.$emit("success");
      }
    },
  },
};
</script>

<style scoped lang="less">
.forget {
  height: 100%;

  .password {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    &-text {
      cursor: pointer;
    }
  }
  .floot {
    margin-top: 53px;
    text-align: center;
    &-register {
      color: var(--primary);
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 500;
      text-decoration: underline;
      margin-top: 30px;
    }
  }
  .send-btn {
    position: relative;
    top: 11px;
  }
  &-btn {
    width: 100%;
    font-size: 20px;
  }
}
</style>
