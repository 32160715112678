export default {
  namespaced: true,
  state() {
    return {
      profile: {},
      token: "",
    };
  },
  getters: {
    getUser: (state) => state.profile,
    getToken: (state) => state.token,
  },
  mutations: {
    setUser(state, payload) {
      state.profile = { ...payload };
    },
    clear (state) {
      state.token = ""
      state.profile = {}
    },
    setToken(state, value) {
      state.token = value;
    },
  },
};
