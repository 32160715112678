const zh_CN = {
  nav: {
    chi: "简体中文",
    eng: "英语",
    personalCenter: "个人中心",
    logout: "退出登录",
  },
  areaCode:'选择区号',
  place: "请输入",
  sure: "确定",
  cancel: "取消",
  moneyIcon: "₦",
  footText: "版权所有",
  aginLogin: "登录过期,请重新登录",
  sellCard: {
    price: "回收价格",
    take: "注意",
    request1: "我们只要实体卡",
    request2: "请选择正确的类型，否则我们将在网页上加载您的卡并支付实时价格。",
    cardImg: "卡片图片",
    click: "点击",
    upImg: "上传图片",
    cardCode: "卡片Code",
    cardMoney: "卡片金额",
    expectMoney: "预计金额",
    expectInte: "预计获得积分",
    timeout: "超时补偿",
    expeTimeout:
      "如果订单加载成功但处理时间超过 15 分钟，将额外为您添加卡实际金额的 0.5% 作为超时补偿",
    placeCardMoney: "输入您的卡片金额",
    placeCard: "如果您的卡看不清代码，请在此处输入代码",
  },
  home: {
    login: "登录/注册",
    type: "类型",
    chooseCard: "选择卡片",
    upCard: "上传卡片",
    sellCard: "卖卡",
    home: "首页",
    waitCheck: "等待验证",
    pay: "支付到账",
    card: "实体卡",
    code: "编码",
    recovery: "回收价格",
    faceValue: "面值",
    price: "价格",
    timeout: "超时补偿",
    mysell: "我要卖卡",
    timetext: "订单未在指定时间内处理，时间会补偿你",
    dollar: "美元",
    class: "种类",
    suc: "成功核销",
    payCard: "我要卖卡",
    seek: "如果找不到你要卖的卡，可以问客服！！",
    hope: "希望你可以把我们推荐给更多的人",
    invite:
      "邀请朋友使用我们的网站.当您的朋友完成第一 次成功交易时，您可以获得1000",
    noTips: "不再显示提示",
    ok: "好的，我知道了",
  },
  foot: {
    home: "主页",
    wallet: "钱包",
    order: "订单",
    personal: "我的",
    rule: "规则",
  },
  wallet: {
    inteLack: "积分不足",
    pcmoney: "金额",
    edit: "编辑",
    del: "删除",
    noSome: "暂无数据",
    title: "钱包",
    all: "总资产",
    myWall: "我的钱包",
    addCard: "添加新银行卡",
    balance: "收入明细",
    draw: "提现",
    myCard: "我的银行卡",
    credit: "我的积分",
    identity: "身份认证",
    drawHis: "提现历史",
    warin: "身份认证通过后不可修改",
    name: "名",
    surname: "姓",
    idNum: "证件号码",
    actual: "实际名称",
    front: "正面",
    back: "背面",
    idFront: "证件正面",
    handleId: "手持证件正面",
    drawErr: "提现失败",
    getMoneyErr: "获取金额失败",

    addMyCard: "添加本人信用卡或银行卡",
    cardNum: "银行卡号",
    cardType: "卡的种类",
    placeCardType: "请选择卡的种类",
    cardName: "持卡人姓名",
    sureEidt: "保存",
    delCard: "删除银行卡",
    drawTitle: "提现历史",
    drawDate: "提现日期",
    drawMoney: "提现金额",
    drawFlow: "提现去向",
    drawType: "提现状态",
    tranMoney: "交易金额",
    tranTime: "交易时间",
    tranType: "交易卡片类型",
    drawFlag: "类型",
    drawAll: "全部",
    drawDoing: "处理中",
    drawSuccess: "提现成功",
    drawFail: "提现失败",
    chooseType: "选择类型",
    noHistory: "无历史记录",
    tips: "提示",
    tipsMsg: "请填写正确的银行信息和姓名,否则您的提款将不能正常到达",
    sureDel: "是否确认删除",

    balanceIntegral: "积分获取",
    newPhone: "新人电话号码",
    balanceDate: "收入日期",
    balanceMoney: "收入金额",
    exchangeMoney: "兑换金额",
    balanceChoose: "选择明细",

    balanTrans: "交易明细",
    balanRebate: "返佣明细",
    balanInt: "积分兑换",

    drawNum: "交易收入",
    inviteNum: "邀请返佣金收入",
    integralNum: "积分兑换收入",

    /**
     * 提现部分
     */
    drawCard: "提现到银行卡",
    cDrawCard: "选择提现银行卡",
    acceptCard: "到账银行卡",
    moneyPassword: "资金密码",
    nowDraw: "立即到账",
    drawMoneyNum: "提现金额",
    allDarw: "全部提现",
    surplus: "可用金额",
    sureDraw: "确认提现",
    drawText1: "转账金额不能低于0.00",
    drawText2: "金额将实时转入对方账户，不可退还",
    placeChoose: "请选择",
    waitLook: "稍后请查看账户余额",
    beyond: "超出可用余额，请重新填写",

    /**
     * 积分模块
     */
    inteTitle: "我的积分",
    inte: "积分",
    inteDetail: "积分明细",
    exchangeInte: "兑换积分",
    exchangeRate: "汇率",
    inteIncome: "积分收入",
    exchType: "兑换方式",
    exchToCard: "兑换至余额",
    exchHistory: "兑换记录",
    chooseExch: "选择兑换金额",
    exchange: "兑换",
    exchangeFail: "兑换失败",
    exchangeSuccess: "兑换成功",
    allInte: "总积分",
  },

  order: {
    title: "我的订单",
    inType: "订单交易获得积分",
    inToMoney: "积分兑换金额",
    info: "订单信息",
    date: "按日期",
    all: "全部",
    doing: "处理中",
    look: "查看",
    success: "成功",
    fail: "失败",
    reset: "重置",
    sure: "确认",
    types: "交易状态",
    operation: "交易操作",
    orderSuccess: "订单成功",
    orderFail: "订单失败",
    orderDoing: "订单处理中",
    status: "订单状态",
    timer: "下单时间",
    cardValue: "卡片面额",
    cardType: "卡片类型",
    cardCode: "卡片Code",
    cardImg: "卡片上传截图",
    orderScore: "获取积分",
    totalPrice: "订单总价",
    orderTime: "订单时间",
    orderNum: "订单编号",
    cardTypeName: "实体卡",
    detailOrder: "订单详情",
    typeImg: "卡图",
    typePass: "卡密",
    viewImg: "查看原图",
  },
  update: {
    nameTitle: "修改昵称",
    whatsappTitle: "修改WHATSAPP ID",
    namePlace: "请输入昵称",
    whatsappLbel: "修改WHATSAPP ID",
    whatsappPlace: "请输入WHATSAPP ID",
    pcSex: "请选择性别",
    btn: "确认修改",
  },
  person: {
    safe: "安全设置",
    pcNickname: "您的昵称",
    pcDate: "您的生日",
    pcDateErr: "请选择您的生日",
    sexMan: "男",
    updataPass: "修改密码",
    sexGirl: "女",
    sexNone: "不想透露",
    number: "编号",
    information: "基本信息",
    identity: "身份认证",
    identSucc: "提交成功",
    invitation: "我的邀请",
    team: "我的团队",
    pcNext: "直属下级",
    evaluate: "对我们的评价",
    help: "帮助中心",
    contact: "联系我们",
    loginOut: "登出",
    nickname: "昵称",
    tel: "手机号码",
    email: "电子邮件",
    whatsappid: "WHATSAPP ID",
    birthday: "生日",
    sex: "性别",
    password: "登录密码",
    onpassword: "付款密码",
    unbind: "未绑定",
    birthdaymsg: "未填写生日",
    sexmsg: "未填写性别",
    // contact: "如何联系我们",
    servicetel: "客服电话",
    serviceon: "在线客服",
    know: "我知道了",
    contanceServicer: "联系在线客服",
    noName: "未填写昵称",
    noTel: "未绑定电话",
    language: "语言设置",
  },
  help: {
    title1: "如何交易礼品卡",
    title2: "关于礼品卡交易",
    title3: "关于信用贷款",
    title4: "关于钱包及提现",
    title5: "如何使用礼品卡网站",
  },
  team: {
    title: "我的团队",
    url: "链接邀请",
    commission: "总佣金",
    todayCommission: "今日佣金",
    firCommission: "一级佣金",
    secCommission: "二级佣金",
    thirCommission: "三级佣金",
    detailCommission: "佣金明细",
    subordinate: "查看下级",
    income: "佣金收入",
    level: "一级",
    seclevel: "二级",
    thirdlevel: "三级",
    user: "用户",
    detailTitle: "下级",
    nodata: "暂无数据",
  },
  login: {
    title: "登录",
    passwordName: "密码",
    haveCode: "获取验证码",
    numCode: "秒后再次获取",
    emailprogress: "通过电子邮箱注册",
    firstemailLabel: "电子邮箱注册",
    register: "注册",
    success: "注册成功",
    whatsapp: "WHATSAPP 号码",
    thirdpass: "请输入密码",
    thirdagainpass: "输入密码",
    firstcodeLabel: "请输入验证码",
    firstsend: "发送代码",
    emerrmsg: "邮箱格式不正确",
    emailmsg: "请输入您的电子邮箱",
    codemsg: "请输入您的验证码",
    donebtn: "完成注册",
    goback: "上一步",
    nextbtn: "下一步",
    backlogin: "返回登录",
    passplacehold: "请输入您的密码",
    WHATSAPplace: "请输入您的WHATSAPP 号码",
    againpass: "确认密码",
    forget: "忘记密码",
    remerber: "记住密码",
    passlogin: "密码登录",
    emailCode: "邮箱账户",
    userPass: "账户密码",
    againmsg: "两次密码不一样，请重新输入",
    againpassmsg: "请确认您的密码",
    passLength: "密码的长度请在6-18位之间",
    passRrr: "密码不能包含中文",
  },
  password: {
    sixPassErr:'密码只能输入6位数字',
    titlelogin: "设置登录密码",
    titepay: "设置支付密码",
    old: "旧密码",
    new: "新密码",
    confirm: "重新确认",
    tel: "电话号码",
    code: "验证码",
    btn: "确认修改",
    send: "发送代码",
    paypass: "付款密码（6位数字）",
    payagain: "确认密码（6位数字）",
    oldmsg: "请输入您的旧密码",
    newmsg: "请输入您的新密码",
    confirmmsg: "请再次输入您的新密码",
    code: "验证码",
    cosemsg: "请再次输入您的新密码",
    paymsg: "请输入支付密码",
    agpaymsg: "再次输入支付密码",
    errFormat: "格式不正确",
    nummFormat: "请输入密码",
    diffMsg: "两次密码不一致",
    successMsg: "修改成功",
    successSend: "发送成功",
  },
  pcinformation: {
    telTitle: "设置手机号",
    telLabel: "手机号",
    telPlace: "请输入新手机号",
    success: "操作成功",
  },
  bind: {
    teltitle: "绑定手机号",
    emailtitle: "修改邮箱",
    tel: "手机号码",
    code: "验证代码",
    btnsub: "确认绑定",
    send: "发送代码",
    email: "电子邮箱",
    emailmsg: "请输入新的电子邮箱",
    codemsg: "请输入您的验证代码",
    telmsg: "请输入您的手机号",
    telcode: "请输入您的短信代码",
    telErr: "手机号格式不正确",
  },
  invitation: {
    yes:'是',
    no:'否',
    addCode: '邀请码',
    addCodePlace:'请输入您的邀请码',
    pcTime: "时间",
    pcTrue: "交易成功",
    pcFalse: "交易失败",
    successCopy: "复制成功",
    pcTitle: "邀请规则",
    pcCopy: "复制",
    pcknowBtn: "知道了",
    titlehead: "我的邀请",
    invitation: "邀请新用户完成第一个订单",
    myCard: "我的邀请码",
    coppy: "复制邀请链接",
    reward: "我的奖励",
    amount: "奖励总金额",
    available: "可用总金额",
    total: "总交易",
    num: "邀请总数",
    success: "已成功邀请",
    day: "邀请日期",
    deal: "是否交易",
    ruleTitle: "活动规则",
    one: "奖励一：邀请好友注册并完成首卡销售获得奖励",
    two: "奖励二：邀请好友连续卖卡。您可以从每个订单中获得奖励",
    knowBtn: "我知道了",
  },
  notic: {
    title: "通知",
    date: "通知日期",
    content: "通知内容",
    idRead: "是否已读",
    read: "已读",
    noRead: "未读"
  },
  pcPerson: {
    surname: "请输入您的姓",
    name: "请输入您的名",
    idNum: "请输入您的证件号码",
    idBack: "证件背面",
    pcUploaduploadidBack: "请上传证件背面照",
    pcUploaduploadhandleId: "请上传手持证件正面照",
    pcUploaduuploadidFront: "请上传证件正面照",
    choseTome:'请选择时间',
    copySuccess: "复制成功",
    redeSuccess: "兑换成功"
  },
  notic: {
    title: "通知",
    date: "通知日期",
    content: "通知内容",
    idRead: "是否已读",
  },
  two: {
    realMsg: "您的实名制正在审核中...",
    whatsLength:'请输入10-11位数字',
    reason: "原因",
    voucher: "凭证",
    payDetail: '提现详情',
    payHistory: '提现记录',
    paySucess: '提现成功',
    payFail: '提现失败',
    ayFailreason: '提现失败原因',
    payvalue: '提现金额',
    userId: '用户ID',
    payTime: '提现时间',
    back: '提现银行',
    suppose: '打款证明',
    view: '查看原因',
    text: '您的提现到账可能会有延迟，如有疑问，请联系客服',
    noImag: '暂无图片',
    uploadcardImg: "请上传卡片截图",
    idFront: "请上传证件正面照",
    whatsapp: "请输入WHATSAPP",
    whatsappCode:"请选择WHATSAPP区号",
    areaCode:"请选择电话区号",
    idRight: "请上传手持证件正面",
    phonenumber: "请输入电话号码",
    idNumber: "请输入证件号码", // 证件号码
    surname: "请输入您的姓",
    name: "请输入您的名",
    idNum: "请输入您的证件号码",
    idBack: "请上传证件背面照",
    getErr: "获取金额失败",
    loginOut: "登录过期，请重新登录",
    conFail: "服务器连接失败",
    updateTime: "更新时间",
    noTotic:'目前没有通知',
    registerTime:'注册时间',
    isDeail:'是否交易',
    transaction:'已交易',
    untransaction:'未交易',
    orderSuccess: "订单完成",
    Points:"积分来源",
    pointsBal: "积分余额",
    account:'账号',
    noaccount:'没有账号？',
    registerNoe:'立即注册',
    email:'个人电子邮箱',
    emailCode:'邮箱验证码',
    addCode:'邀请码(选填)',
    regeisterTitle:'注册个人账户',
    drawCard: "提现至银行卡",
    sureConvert: "确认兑换到余额",
    cardType: "礼品卡类型",
    searCard: "搜索礼品卡报价",
    got: "获得了",
    lookMore: "查看更多",
    service: "简单、快速、优质的服务",
    contact: "如何联系我们",
    search: "搜索",
    searchResult: "暂无搜索结果",
    typeChoose: "类型选择",
    sellCard: "我要卖卡",
    Copylink:'复制您的邀请链接',
    Transaction:'交易排行榜',
    Commissioned:'已获得佣金',
    cash:'我要提现',
    Explanation :'活动规则讲解',
    ExplanationOne:'邀请好友注册并完成首卡销售获得奖励',
    ExplanationTwo:'邀请好友连续卖卡。您可以从每个订单中获得奖励',
    Contactservice:'联系客服',
    tipsWhatscode:'whatsapp id已经绑定，为了保证 您账户的安全，请联系客服修改',
    tipsPhone:'手机号已经绑定，为了保证 您账户的安全，请联系客服修改',
    ordeAmount:'订单金额',
    earnPoit:'获得积分',
    uploadScreenshot:'上传截图',
    orderFail:'订单失败原因',
    receivedAmount:'到账金额',
    commission:'佣金',
    invationnodata:'暂时还无人上榜',
    invitefriends:'邀请好友获得佣金',
    Filled:"好友填写",
    youinvitation:"您的邀请码",
    successfulTrade:"好友交易成功",
    commissionReceived:'佣金到账',
    more: "如果我们加载您的卡超过",
    compensate: "分钟，我们将给予补偿。",
    lookMore: "查看更多",
    cTitle0: "快速了解我们，帮助您有效的使用功能",
    cTitle1: "将礼品卡换成现金",
    cTitle2: "邀请好友交易完成可以获得 佣金返还",
    cTitle3: "安全快速帮您完成订单",
    cText0: "视频中的内容会解决您90%的问题",
    cText1: "提交您的礼品卡详细信息。查看我们的报价。 单击接受并将您的卡变成现金......或加密货币！",
    cText2: "您的好友注册时填写你的邀请码，完成交易 您可以获取佣金。",
    cText3: "专业团队，安全保密，保证您卡片和资金的安全性， 提现打款快速，订单处理快速",
    sellCard: "出售礼品卡",
    invite: "立即邀请",
    need: "我们需要你手中的",
    searchCard: "搜索你需要的礼品卡",
    giftCard: "礼品卡",
    noResult: "暂无搜索结果",
    addTwxt:'添加',
    enterCardName:'请输入持卡人姓名',
    enterCardCode:'请输入银行卡号',
    orderScussImg:'完成截图',
    orderErrImg:'订单失败截图',
    wallWay:'选择提现方式',
    bankCard:'银行卡',
    choseBank:'请选择银行卡',
    whatsAppTICP:'WHATSAPP ID已经绑定，为了保证您的账户安全，请',
    updataName:'修改',
    phoneTicp:'手机号码已经绑定，为了保证您的账户安全，请',
    loading:'加载中'
  }
};
export default zh_CN;
