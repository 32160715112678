import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "@/assets/css/element/index.css";
import "@/assets/css/vars.css";
import "@/assets/css/devstyle.less";
import router from "./router/index";
import i18n from "@/lang/index";
import store from "./store";
import Breadcrumb from "@/components/Breadcrumb.vue"
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
Vue.config.productionTip = false;
Vue.component('Breadcrumb',Breadcrumb)
Vue.use(VueVideoPlayer)
Vue.use(ElementUI);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
