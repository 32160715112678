<!-- -->
<template>
  <div class="header">
    <div class="c-bet header-main">
      <div>
        <img src="@/assets/images/login/logo.png" alt="" />
        <span class="logo-name">Palmfun</span>
      </div>
      <div v-if="!token">
        <div class="header-btn" @click="onLogin">{{ $t("login.title") }}</div>
        <div class="header-btn header-btn-register" @click="onRegister">{{ $t("login.register") }}</div>
        <div @click="onShowLang" class="header-menu header-lang">
          {{langList.find(v => v.key == langKey).title}}
        </div>
      </div>
      <div v-else class="header-menu">
        
        <div v-for="(item, index) in menuList" :key="index" @click="onClick(item)" :class="{ 'header-choose': $route.path.includes(item.path) }">
          {{ $t(item.title) }}
        </div>

          <img :src="userInfo.avatar || defaultAvatar" alt="" @click="$router.push('/person/1')" />
        <div @click="onShowLang" class="langulage">
          {{langList.find(v => v.key == langKey).title}}
        </div>
      </div>
    </div>
    <login ref="login" @onForget="onForget" @onRegister="onRegister" />
    <forget ref="forget" @onForget="onForget" />
    <register ref="register" @success="success" />
    
    <el-dialog append-to-body class="lang" width="430px" :show-close="false" :visible.sync="dialogTableVisible">
      <div v-for="(lang, index) in langList" :key="index" @click="onChooseLang(lang)" :class="{'c-primary': lang.key == langKey} " class="choose-lang">
        {{ lang.title }}
      </div>
    </el-dialog>
  </div>
</template>
<script>
import login from "@/pages/login/index";
import forget from "@/pages/login/forgatepassword/index";
import register from "@/pages/login/register";
import { getToken, getLang } from "@/utils/util";
import { chageLange } from "@/lang"
export default {
  name: "",
  components: { login, forget, register },
  data() {
    let that = this;
    return {
      userInfo: {},
      defaultAvatar: require("@/assets/images/avatar.png"),
      menuList: [
        { title: "home.home", path: "/home" },
        { title: "home.sellCard", path: "/sellCard" },
        { title: "foot.order", path: "/order" },
        { title: "foot.wallet", path: "/wallet" },
        { title: "foot.rule", path: "/rule" },
      ],
      dialogTableVisible: false,
      langList: [
        
        { title: "English", key: "en-US" },
      ],
      langKey: getLang(),
    };
  },
  computed: {
    token() {
      return this.$store.getters['user/getToken']
    }
  },
  methods: {
    onChooseLang(lang) {
      this.langKey = lang.key
      chageLange(lang.key)
      this.onShowLang()
    },
    onShowLang() {
      this.dialogTableVisible = !this.dialogTableVisible
    },
    onLogin() {
      this.$refs.login.open();
      // this.$router.push('/login');
    },
    onForget() {
      this.$refs.forget.open();
    },
    onRegister() {
      this.$refs.register.open();
    },
    success() {
      //注册成功
      this.$refs.login.open();
    },
    onClick(item) {
      this.$router.push(item.path);
    },
  },
  created() {
    this.$store.commit("onClose")
    if (process.env.NODE_ENV == 'development') {
      this.langList.push({ title: "中文", key: "zh-CN" })
    }  
  }
};
</script>
<style scoped lang="less">
.header {
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  height: 80px;
  background: #fff;
  .logo-name{
    color:var(--primary);
    position: relative;
    top:-13px;
    font-size:18px;
    margin-left:10px
  }
  &-lang {
    display: inline-block !important;
    margin-left: 40px;
   
  }
  .langulage{
    margin-left:20px;
    border:0px;
    box-shadow: none;
  }
  &-main {
    width: 1300px;
    margin: 0 auto;
    align-items: center;
    height: 100%;
    img {
      height: 40px;
    }
  }
  &-btn {
    display: inline-block;
    font-size: 24px;
    padding: 10px 45px;
    line-height: 1;
    color: var(--primary);
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: 4px 3px 10px 0px rgba(0,0,0,0.1);
    border-radius: 20px;
    &-register{
      color:#A1A1A1
    }
  }
 
  &-btn + &-btn {
    margin-left: 20px;
  }
  &-menu {
    font-size: 18px;
    color: #666666;
    
    display: flex;
    // justify-content: space-around;
    cursor: pointer;
    height: 100%;
    align-items: center;
    div {
      padding:11px 32px;
      text-align: center;
      background: #FFFFFF;
      box-shadow: 4px 3px 10px 0px rgba(0,0,0,0.1);
      border-radius: 20px;
      margin-right:24px;
    }
    img {
      border-radius: 50%;
    }
  }
  &-choose {
    color: var(--primary);
    position: relative;
    border:1px solid var(--primary);
  }
  &-choose::after {
    // background: var(--primary);
    // position: relative;
    // content: " ";
    // top: 11px;
    // left: 35px;
    // border-radius: 10px;
    // display: block;
    // width: 60px;
    // height: 4px;
  }
}
</style>
<style lang="less">
.lang {
  .el-dialog__header {
    padding: 0;
  }
}
.choose-lang {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #a1adc1;
  text-align: center;
  height: 54px;
  line-height: 54px;
  cursor: pointer;
}
</style>
