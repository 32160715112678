import axios from "axios";
import router from "@/router";
import { translate as $t } from "@/lang"
 // import store from "@/store";
const baseURL = ""
const service = axios.create({
    baseURL,
    timeout: 20000,
});
// 发起请求之前的拦截器
import { getToken, clearToken, getLang } from "@/utils/util";
import { Message } from 'element-ui'

service.interceptors.request.use(
    (config) => {
        // 如果有token 就携带tokon
        if (config.headers.type == 2) {
            config.headers['Content-Type'] = "application/json;charset=UTF-8"
            delete config.headers.type
        }
        config.headers.lang = getLang()
        const token = getToken();
        if (token) {
            config.headers.Authorization = token;
        }
        if (Object.prototype.hasOwnProperty.call(config, "token")) {
            config.headers.Authorization = config.token;
        }
        return config;
    },
    (error) => Promise.reject(error)
);


// 响应拦截器
service.interceptors.response.use(
    (response) => {
        const res = response.data;
        if (res.code == 401) {
            Message($t('aginLogin'));
            clearToken()
            router.replace({
                path: "/home"
            })
            return res
        }
        if (response.status !== 200) {
            return Promise.reject(res);
        } else if (res.code != 200) {
            console.log("错误提示", res)
            Message(res.msg);
            Promise.reject(res);
            return res
        } else {
            return res;
        }
    },
    (error) => {
        Message("服务器连接失败");
        return Promise.reject(error);
    }
);
export default service;
