import service from "@/utils/request";

/**
 *
 * @param { string } email  邮箱
 * @param { string } whatsappId
 * @param { string } password 密码
 * @param { string } [inviteId]  邀请用户ID
 * @returns 注册2
 */
export function register(params = {}) {
  return service({
    url: "/web/0/register",
    method: "post",
    params,
  });
}

/**
 *
 * @param { string } email  邮箱
 * @param { string } type 0 注册 1 修改登录密码 2 支付密码
 * @returns 通过邮箱注册
 */
export function sendEmailCode(params = {}) {
  return service({
    url: "/web/0/sendEmailCode",
    method: "post",
    params,
  });
}

/**
 *
 * @param { string } email  邮箱
 * @param { string } code 验证码
 * @returns 注册一下一步
 */
export function verificationCode(params = {}) {
  return service({
    url: "/web/0/verificationCode",
    method: "post",
    params,
  });
}

/**
 *
 * @param { string } email  邮箱
 * @param { string } password 密码
 * @returns
 */
export function login(params = {}) {
  return service({
    url: "/web/0/login",
    method: "post",
    params,
  });
}

/**
 * 
获取用户信息
 * @returns 
 */
export function getUserInfo(params = {}) {
  return service({
    url: "/web/getUserInfo",
    method: "get",
    params,
  });
}

/**
 * 
修改用户信息
 * @returns 
 */
export function updateUserInfo(data = {}) {
  return service({
    url: "/web/updateUserInfo",
    method: "post",
    data,
  });
}
/**
 * 
获取邀请信息
 * @returns 
 */
export function getStatistics(data = {}) {
  return service({
    url: "/web/invite/getStatistics",
    method: "get",
    data,
  });
}
/**
 * 
邀请用户列表
 * @returns 
 */
export function getInviteUser(data = {}) {
  return service({
    url: "/web/invite/getInviteUser",
    method: "post",
    data,
  });
}
/**
 * 
修改支付密码
 * @returns 
 */
export function setPayPassword(data = {}) {
  return service({
    url: "/web/setPayPassword",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
}
/**
 * 
修改登录密码
 * @returns 
 */
export function updateLoginPassword(data = {}) {
  return service({
    url: "/web/updateLoginPassword",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
}

/**
 * 
忘记密码
 * @returns 
 */
export function retrievePassword(data = {}) {
  return service({
    url: "/web/0/retrievePassword",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
}

/**
 * 
获取帮助中心类型列表
 * @returns 
 */
export function helpTypeList(data = {}) {
  return service({
    url: "/web/hc/type/list",
    method: "get",
    data,
  });
}
/**
 * 
获取帮助中心类型
 * @returns 
 */
export function helpTList(id) {
  return service({
    url: "/web/hc/type/" + id,
    method: "get",
  });
}
/* 
获取帮助中心内容
 * @returns 
 */
export function helpContent(id) {
  return service({
    url: "/web/hc/content/" + id,
    method: "get",
  });
}
/* 
获取d订单列表
 * @returns 
 */
export function getOrders(params) {
  return service({
    url: "/web/order/getOrders",
    method: "post",
    params,
  });
}

/* 
联系我们列表
 * @returns 
 */
export function contactUs(params) {
  return service({
    url: "/web/contactUs/list",
    method: "get",
    params,
  });
}
/* 
我的团队，佣金明细
 * @returns 
 */
export function statistics(params) {
  return service({
    url: "/web/team/statistics",
    method: "get",
    params,
  });
}
/* 
我的团队，下级用户
 * @returns 
 */
export function getSubordinateInfo(params) {
  return service({
    url: "/web/team/getSubordinateInfo",
    method: "get",
    params,
  });
}
/* 
我的团队，下级用户
 * @returns 
 */
export function getCommissionDetail(params) {
  return service({
    url: "/web/team/getCommissionDetail",
    method: "get",
    params,
  });
}
/* 
获取订单详情
 * @returns 
 */
export function getSingleOrder(params) {
  return service({
    url: "/web/order/getSingleOrder",
    method: "post",
    params,
  });
}
/* 
获取区号
 * @returns 
 */
export function countryList(params) {
  return service({
    url: "/web/system/country/list",
    method: "get",
    params,
  });
}