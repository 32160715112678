<template>
  <el-dialog
    :title="$t('person.contact')"
    :visible.sync="centerDialogVisible"
    width="500px"
    center
  >
    <div class="modal-body">
      <div v-for="(item, index) in contentArr" :key="index" class="text">
        {{ index + 1 }}、{{ item.name }} :<a :href="'tel:' + item.val">{{
          item.val
        }}</a>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="centerDialogVisible = false"
        round
        class="btn"
        >{{ $t("invitation.pcknowBtn") }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { contactUs } from "@/api/user";
export default {
  data() {
    return {
      centerDialogVisible: false,
      contentArr: [],
    };
  },
  methods: {
    open() {
      contactUs().then((res) => {
        console.log(res);
        this.contentArr = res.data;
      });
      this.centerDialogVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
:deep(.el-dialog__header) {
  padding-top: 30px;
}
:deep(i.el-dialog__close.el-icon.el-icon-close) {
  font-size: 20px;
}
:deep(.el-dialog__body) {
  padding-left: 41px;
  padding: 41px;
}
.btn {
  width: 400px;
}
.text {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 39px;
}
</style>
