const en_US = {
  nav: {
    chi: "Simplified Chinese",
    eng: "English",
    personalCenter: "Personal Center",
    logout: "Log out"
  },
  areaCode:'Select area code',
  place: "Please enter",
  sure: "Determine",
  cancel: "Cancel",
  moneyIcon: "₦",
  footText: "Copyright",
  aginLogin: "Login expired, please login again",
  sellCard: {
    price: "Recovery price",
    take: "Be careful",
    request1: "We only need physical cards",
    request2: "Please select the correct type, otherwise we will load your card on the webpage and pay the real-time price。",
    cardImg: "Card Picture",
    click: "Click",
    upImg: "Upload pictures",
    cardCode: "Card code",
    cardMoney: "Card amount",
    expectMoney: "Estimated amount",
    expectInte: "Expected points",
    timeout: "Overtime compensation",
    expeTimeout:
      "If the order is uploaded successfully but the processing time exceeds 15 minutes, 0.5% of the actual amount of the card will be added for you as overtime compensation",
    placeCardMoney: "Enter your card amount",
    placeCard: "If your card cannot read the code, enter the code here",
  },
  home: {
    login: "Login/Registration",
    type: "Type",
    chooseCard: "Select card",
    sellCard: "Selling cards",
    home: "Home page",
    upCard: "Upload card",
    waitCheck: "Waiting for verification",
    pay: "Payment to account",
    card: "Physical card",
    code: "Code",
    recovery: "Recovery price",
    faceValue: "Face value",
    price: "Price",
    timeout: "Overtime compensation",
    mysell: "I want to sell cards",
    timetext: "If you have uploaded your order and we do not process it within the specified time shown, you will receive a timeout bonus.",
    dollar: "Dollar",
    class: "Type",
    suc: "Success",
    payCard: "I want to sell cards",
    seek: "If you can't find the card you want to sell, you can ask the customer service！！",
    hope: "I hope you can recommend us to more people",
    invite:
      "Invite friends to use our website When your friend completes the first successful transaction, you can get 1000",
    noTips: "Don't show me again",
    ok: "Ok, I got it.",
  },
  foot: {
    home: "Homepage",
    wallet: "Wallet",
    order: "Order",
    personal: "My",
    rule: "Rule",
  },
  wallet: {
    inteLack: "Insufficient points",
    pcmoney: "Amount of money",
    edit: "Edit",
    del: "Delete",
    noSome: "No data",
    title: "Wallet",
    all: "total assets",
    myWall: "My wallet",
    addCard: "Add bank card",
    balance: "Revenue Details",
    draw: "Withdrawal",
    myCard: "My bank card",
    credit: "My Points",
    identity: "Identity authentication",
    drawHis: "Withdrawal history",
    warin: "It cannot be modified after identity authentication",
    name: "Name",
    surname: "Surname",
    idNum: "ID",
    actual: "First name",
    front: "Front",
    back: "Back",
    idFront: "Front of certificate",
    handleId: "Front of hand-held certificate",
    drawErr: "Withdrawal failed",
    getMoneyErr: "Failed to get the amount",

    addMyCard: "Add your own credit card or bank card",
    cardNum: "Bank card No",
    cardType: "Type of card",
    placeCardType: "Please select the type of card",
    cardName: "Cardholder name",
    sureEidt: "Preservation",
    delCard: "Delete bank card",
    drawTitle: "Withdrawal history",
    drawDate: "Withdrawal date",
    drawMoney: "Withdrawal amount",
    drawFlow: "Withdrawal destination",
    drawType: "Withdrawal status",
    tranMoney: "Transaction amount",
    tranTime: "Transaction time",
    tranType: "Transaction card type",
    drawFlag: "Type",
    drawAll: "Whole",
    drawDoing: "Processing",
    drawSuccess: "Withdrawal succeeded",
    drawFail: "Withdrawal failed",
    chooseType: "Select type",
    noHistory: "No history",
    tips: "Tips",
    tipsMsg: "Please fill in the correct bank information and name, otherwise your withdrawal will not arrive normally",
    sureDel: "Are you sure to delete",

    balanceIntegral: "Score acquisition",
    newPhone: "New person's telephone number",
    balanceDate: "Revenue date",
    balanceMoney: "Revenue amount",
    exchangeMoney: "Exchange amount",
    balanceChoose: "Select details",

    balanTrans: "Transaction details",
    balanRebate: "Details of commission return",
    balanInt: "Redemption of points",

    inviteNum: "Commission income from invitation return",
    drawNum: "Transaction income",
    integralNum: "Income from point exchange",

    /**
     * 提现部分
     */
    drawCard: "Withdrawal to bank card",
    cDrawCard: "Select withdrawal bank card",
    acceptCard: "Incoming bank card",
    moneyPassword: "Fund password",
    nowDraw: "Immediate arrival",
    drawMoneyNum: "Withdrawal amount",
    allDarw: "Withdrawal in full",
    surplus: "Available amount",
    sureDraw: "Confirm withdrawal",
    drawText1: "Transfer amount cannot be less than 0.00",
    drawText2: "The amount will be transferred to the other party's account in real time and cannot be returned",
    placeChoose: "Please select",
    waitLook: "Please check your account balance later",
    beyond: "Please fill in again if the available balance is exceeded",

    /**
     * 积分模块
     */
    inteTitle: "My points",
    inte: "integral",
    inteDetail: "Points details",
    exchangeInte: "Redeem points",
    exchangeRate: "exchange rate",
    inteIncome: "Income from points",
    exchType: "Exchange method",
    exchToCard: "Exchange to balance",
    exchHistory: "Exchange record",
    chooseExch: "Select exchange amount",
    exchange: "Exchange",
    exchangeFail: "Exchange failed",
    exchangeSuccess: "Redemption succeeded",
    allInte: "Total points",
  },

  order: {
    title: "My order",
    inType: "Order transaction gains points",
    inToMoney: "Redemption amount of points",
    info: "Order information",
    date: "By date",
    all: "Whole",
    doing: "Processing",
    look: "See",
    success: "Success",
    fail: "Fail",
    reset: "Reset",
    sure: "Confirm",
    types: "Transaction status",
    operation: "Transaction operation",
    orderSuccess: "Order succeeded",
    orderFail: "Order failed",
    orderDoing: "Order processing",
    status: "Order status",
    timer: "Order time",
    cardValue: "Card denomination",
    cardType: "Card type",
    cardCode: "Card code",
    cardImg: "Card upload screenshot",
    orderScore: "Get points",
    totalPrice: "Total order price",
    orderTime: "Order time",
    orderNum: "Order No",
    cardTypeName: "Physical card",
    detailOrder: "Order details",
    typeImg: "Cartography",
    typePass: "Code",
    viewImg: "View the original picture",
  },
  update: {
    nameTitle: "Modify nickname",
    whatsappTitle: "Modify WHATSAPP ID",
    namePlace: "Please enter your nickname",
    whatsappLbel: "Modify WHATSAPP ID",
    whatsappPlace: "Please enter WHATSAPP ID",
    pcSex: "Please select gender",
    btn: "Confirm modification",
  },
  person: {
    safe: "Security setting",
    pcNickname: "Your nickname",
    pcDate: "Your birthday",
    pcDateErr: "Please select your birthday",
    sexMan: "Male",
    updataPass: "Change password",
    sexGirl: "Female",
    sexNone: "Don't want to disclose",
    number: "Number",
    information: "Essential information",
    identity: "Identity authentication",
    identSucc: "Submitted successfully",
    invitation: "My invitation",
    team: "My team",
    pcNext: "Directly subordinate",
    evaluate: "Comments on us",
    help: "Help center",
    contact: "Contact us",
    loginOut: "Log out",
    nickname: "Nickname",
    tel: "Phone number",
    email: "E-mail",
    whatsappid: "WHATSAPP ID",
    birthday: "Birthday",
    sex: "Gender",
    password: "Login password",
    onpassword: "Payment password",
    unbind: "Unbound",
    birthdaymsg: "No birthday",
    sexmsg: "Gender not filled in",
    // contact: "How to contact us",
    servicetel: "Customer service telephone numbers",
    serviceon: "Online service",
    know: "I got it!",
    contanceServicer: "Contact online customer service",
    noName: "Nickname not filled",
    noTel: "Unbound phone",
    language: "Language settings",
  },
  help: {
    title1: "How to trade gift cards",
    title2: "About gift card transactions",
    title3: "About credit loans",
    title4: "About wallet and withdrawal",
    title5: "How to use the gift card website",
  },
  team: {
    title: "My team",
    url: "Link invitation",
    commission: "Total commission",
    todayCommission: "Today commission",
    firCommission: "Level 1 commission",
    secCommission: "Level 2 commission",
    thirCommission: "Level 3 commission",
    detailCommission: "Commission details",
    subordinate: "View subordinates",
    income: "Commission income",
    level: "Class a",
    seclevel: "Second level",
    thirdlevel: "Level III",
    user: "User",
    detailTitle: "Subordinate",
    nodata: "No data",
  },
  login: {
    title: "Sign in",
    passwordName: "Password",
    haveCode: "Get verification code",
    numCode: "Get again in seconds",
    emailprogress: "Register via email",
    firstemailLabel: "Email registration",
    register: "Register",
    success: "Login was successful",
    whatsapp: "WHATSAPP no",
    thirdpass: "Please input a password",
    thirdagainpass: "Input password",
    firstcodeLabel: "Please enter the verification code",
    firstsend: "Send code",
    emerrmsg: "Incorrect mailbox format",
    emailmsg: "Please enter your email address",
    codemsg: "Please enter your verification code",
    donebtn: "Complete registration",
    goback: "Previous step",
    nextbtn: "Next step",
    backlogin: "Back to Login",
    passplacehold: "Please enter your password",
    WHATSAPplace: "Please enter your WHATSAPP number",
    againpass: "Confirm password",
    forget: "Forgot password",
    remerber: "Remember password",
    passlogin: "Password Login",
    emailCode: "Email accounts",
    userPass: "Account password",
    againmsg: "The two passwords are different, please re-enter",
    againpassmsg: "Please confirm your password",
    passLength: "The length of the password should be between 6-18 digits",
    passRrr: "Password cannot contain Chinese",
  },
  password: {
    titlelogin: "Set login password",
    titepay: "Set payment password",
    old: "Old password",
    new: "New password",
    confirm: "Reconfirm",
    tel: "Telephone number",
    code: "Verification code",
    btn: "Confirm modification",
    send: "Send code",
    paypass: "Payment password (6 digits)",
    payagain: "Confirm password (6 digits)",
    oldmsg: "Enter your old password",
    newmsg: "Please enter a new password",
    confirmmsg: "Please confirm the new password",
    cosemsg: "Please enter your new password again",
    paymsg: "Please enter your payment password",
    agpaymsg: "Enter payment password again",
    errFormat: "Incorrect format",
    nummFormat: "Please input a password",
    diffMsg: "Inconsistency between the two inputs",
    successMsg: "Modification succeeded",
    successSend: "Sending succeeded",
  },
  pcinformation: {
    telTitle: "Set phone number",
    telLabel: "cell-phone number",
    telPlace: "Please enter a new phone number",
    success: "Operation succeeded",
  },
  bind: {
    teltitle: "Bind mobile number",
    emailtitle: "Modify mailbox",
    tel: "phone number",
    code: "Verification code",
    btnsub: "Confirm binding",
    send: "Send code",
    email: "E-mail",
    emailmsg: "Please enter a new email address",
    codemsg: "Please enter your verification code",
    telmsg: "Please enter your mobile number",
    telcode: "Please enter your SMS code",
    telErr: "The phone number format is incorrect",
  },
  invitation: {
    yes:'yes',
    no:'no',
    addCode: 'Invitation code',
    addCodePlace:'Please enter your invitation code',
    pcTime: "Time",
    pcTrue: "Successful trade",
    pcFalse: "Transaction failed",
    successCopy: "Copy succeeded",
    pcTitle: "Invitation rules",
    pcCopy: "Copy",
    pcknowBtn: "Got it",
    titlehead: "My invitation",
    invitation: "Invite new users to complete the first order",
    myCard: "My invitation code",
    coppy: "Copy invitation link",
    reward: "My Rewards",
    amount: "Total reward amount",
    available: "Total amount available",
    total: "Total transactions",
    num: "Total invitations",
    success: "Successfully invited",
    day: "Invitation date",
    deal: "Transaction or not",
    ruleTitle: "Activity rules",
    one: "Reward 1: Invite friends to register and complete the first card sales to obtain rewards",
    two: "Reward 2: Invite friends to sell cards continuously. You can get rewards from each order",
    knowBtn: "I got it!",
  },
  pcPerson: {
    surname: "Please enter your last name",
    name: "Please enter your first name",
    idNum: "Please enter your ID",
    idBack: "Back of certificate",
    pcUploaduploadidBack: "Please upload the back photo of the certificate",
    pcUploaduploadhandleId: "Please upload the front photo of your certificate",
    pcUploaduuploadidFront: "Please upload the front photo of your ID card",
    choseTome:'Please select a time'
  },
  notic: {
    title: "Notice",
    date: "Notification date",
    content: "Notice content",
    idRead: "Read or not",
    read: "Read",
    noRead: "Unread"
  },
  pcPerson: {
    surname: "Please enter your last name",
    name: "Please enter your first name",
    idNum: "Please enter your ID number",
    idBack: "Back of certificate",
    pcUploaduploadidBack: "Please upload the back photo of the certificate",
    pcUploaduploadhandleId: "Please upload the front photo of your certificate",
    pcUploaduuploadidFront: "Please upload the front photo of your ID card",
    choseTome: 'Please select a time',
    copySuccess: "Copy succeeded",
    redeSuccess: "Redemption succeeded"
  },
  two: {
    realMsg: 'Your real name system is under review',
    whatsLength: 'Please enter 10-11 digits',
    reason: "reason",
    voucher: "voucher",
    payDetail: 'Details of withdrawa',
    payHistory: 'Withdrawal record',
    paySucess: 'Withdrawal succeeded',
    payFail: 'Withdrawal failed',
    ayFailreason: 'Reason for withdrawal failure',
    payvalue: 'amount',
    userId: 'User ID',
    payTime: 'Withdrawal time',
    back: 'Withdrawing bank',
    suppose: 'Proof of payment',
    view: 'View reason',
    text: 'Your withdrawal may be delayed. If you have any questions, please contact the customer service',
    noImag: 'No picture',
    uploadcardImg: "Please upload the screenshot of the card",
    idFront: "Please upload the front photo of your ID card",
    whatsapp: "Please enter WHATSAPP",
    whatsappCode: "Please select WHATSAPP area code",
    areaCode: "Please select the telephone area code",
    idRight: "Please upload the front of the hand-held certificate",
    phonenumber: "Please enter a phone number",
    idNumber: "Please enter the ID number", // 证件号码
    surname: "Please enter your last name",
    name: "Please enter your first name",
    idNum: "Please enter your ID number",
    idBack: "Please upload the back photo of the certificate",
    getErr: "Failed to get the amount",
    loginOut: "Login expired, please login again",
    conFail: "Server connection failed",
    updateTime: "Update time",
    orderSuccess: "Order completion",
    Points:"Source of points",
    pointsBal: "Balance of points",
    account:'Account number',
    noaccount:'No account?',
    registerNoe:'Register Now',
    email:'Personal Email',
    emailCode:'Email verification code',
    addCode:'Invitation code(optional)',
    regeisterTitle:'Register personal account',
    drawCard: "Withdrawal to bank card",
    sureConvert: "Confirm exchange to balance",
    cardType: "Gift Card Type",
    searCard: "Search gift card quotes",
    got: "got",
    lookMore: "See more",
    service: "Simple, fast and high-quality service",
    contact: "How to contact us",
    searh: "Search",
    searchResult: "No search results",
    typeChoose: "Type selection",
    sellCard: "I want to sell cards",
    noTotic:'No notification at present',
    registerTime:'Registration time',
    isDeail:'Transaction or not',
    transaction:'Traded',
    untransaction:'Not traded',
    Copylink:'Copy your invitation link',
    Transaction:'Transaction ranking',
    Commissioned:'Commissioned',
    cash:'I want to withdraw cash',
    Explanation :'Explanation of activity rules',
    ExplanationOne:'Invite friends to register and complete the first card sales to get rewards',
    ExplanationTwo:'Invite friends to sell cards continuously. You can get rewards from each order',
    Contactservice:'Contact customer service',
    tipsWhatscode:'Whatsapp ID has been bound. To ensure the security of your account, please contact customer service for modification',
    tipsPhone:'The mobile number has been bound. To ensure the security of your account, please contact the customer service for modification',
    ordeAmount:'Order amount',
    earnPoit:'Earn points',
    uploadScreenshot:'Upload screenshot',
    orderFail:'Order failure reason',
    receivedAmount:'Received amount',
    commission:'commission',
    invationnodata:'No one is on the list yet',
    invitefriends:'Invite friends to get commissions',
    Filled:'Filled in by friends',
    youinvitation:"Your invitation code",
    successfulTrade:'Successful trade',
    commissionReceived:"Commission received",
    more: "If we load your card more than",
    compensate: "minutes, we will make compensation.",
    lookMore: "See more",
    cTitle0: "Get to know us quickly and help you use our features effectively",
    cTitle1: "Exchange gift cards for cash",
    cTitle2: "Invite friends to complete the transaction and get commission return",
    cTitle3: "Help you complete your order safely and quickly",
    cText0: "The content in the video will solve 90% of your problems",
    cText1: "Submit your gift card details. Check out our offer. Click Accept and turn your card into cash Or cryptocurrency!",
    cText2: "Your friend fills in your invitation code when registering, and you can get commission after completing the transaction.",
    cText3: "Professional team, security and confidentiality, to ensure the security of your cards and funds, fast cash withdrawal and payment, fast order processing",
    sellCard: "Selling gift cards",
    invite: "Invite Now",
    need: "We need what you have",
    search: "search",
    searchCard: "Search for gift cards you need",
    giftCard: "Gift Card",
    noResult: "No search results",
    addTwxt:'add to',
    enterCardName:"Please enter the cardholder's name",
    enterCardCode:'Please enter the bank card number',
    orderScussImg:'Complete screenshot',
    orderErrImg:'Screenshot of order failure',
    wallWay:'Select withdrawal method',
    bankCard:'bank card',
    choseBank:'Please select a bank card',
    whatsAppTICP:'WHATSAPP ID has been bound. To ensure the security of your account, please',
    updataName:'modify',
    phoneTicp:'The mobile phone number has been bound. To ensure the security of your account, please',
    loading:'loading'
  }
};
export default en_US;
