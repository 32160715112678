// import { useI18n } from "vue-i18n";

import { getUserInfo } from "@/api/user";
export default {
  //   var { t } = useI18n();
  data() {
    //邮箱验证

    return {};
  },
  methods: {
    validateEmail(rule, value, callback) {
      console.log(5555);
      console.log(this.$t("login.emerrmsg"));
      const email =  /^([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;;
      if (!value) {
        return callback(new Error(this.$t("login.emailmsg")));
      } else if (!email.test(value)) {
        return callback(new Error(this.$t("login.emerrmsg")));
      } else {
        return callback();
      }
    },
    //隐藏中间字符
    numberTel(value, num) {
       console.log(value)
      var result = "";
      let end=value.length-num
    if (value) {
    
      result = value.substring(0, 3) + "****" + value.substring(end);
      
      }
      console.log(result)
   return result;
    },
        //邮箱隐藏中间字符
    numberEmail(email) {
        if (String (email).indexOf ('@') > 0) {
        let newEmail, str = email.split('@'), _s = '';

        if (str[0].length > 4) {
            _s = str[0].substr (0, 4);
            for (let i = 0; i < str[0].length - 4; i++) {
                _s += '*';
            }
        } else {
            _s = str[0].substr (0, 1);
            for (let i = 0; i < str[0].length - 1; i++) {
                _s += '*';
            }
        }
          newEmail = _s + '@' + str[1];
          console.log(newEmail)
        return newEmail;
        } else {
               console.log(email)
        return email;
    }
    },
    //6-12密码
    validatePass(rule, value, callback) {
      console.log(3333);
      let reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      if (!value) {
        return callback(new Error(this.$t("login.passplacehold")));
      } else if (value.length < 6 || value.length > 18) {
        return callback(new Error(this.$t("login.passLength")));
      } else if (reg.test(value)) {
        return callback(new Error(this.$t("login.passRrr")));
      } else {
        return callback();
      }
    },
        //10-11whatscode
    whatsCode(rule, value, callback) {
      console.log(3333,this.info.whatsappCode);
      // let reglength = [0-9]{10,11};
      let testTeg1 =  /^[7|8|9]{1}[0-9]{9,10}/
      let tel= /^[0-9]{10,11}$/
      console.log(this.encryWhatscode)
      if(this.encryWhatscode){
        if (!this.encryWhatscode) {
          return callback(new Error(this.$t("login.WHATSAPplace")));
        } else if (this.encryWhatscode.length > 11 || this.encryWhatscode.length < 10) {
          return callback(new Error(this.$t("two.whatsLength")));
        } else if ((this.info.whatsappCode == 234 && !testTeg1.test(this.encryWhatscode))|| !tel.test(this.encryWhatscode) ) {
             return callback(new Error(this.$t("password.errFormat")));
         } else{
          return callback();
        }
      }else{
        if (!value) {
          return callback(new Error(this.$t("login.WHATSAPplace")));
        } else if (value.length > 11 || value.length < 10) {
          return callback(new Error(this.$t("two.whatsLength")));
        } else if ((this.info.whatsappCode == 234 && !testTeg1.test(value)) || !tel.test(value)) {
             return callback(new Error(this.$t("password.errFormat")));
         } else{
          return callback();
        }
      }
      
    },
    async getData() {
      let res = await getUserInfo().catch((err) => {
        console.log(err);
      });
      console.log("获取数据", res.data);
      this.$store.commit("user/setUser", res.data);
    },
    // 校验手机号格式
   validatePhone (rule, value, callback)  {
    console.log(3333,this.encrytel);
     const TEL_REGEXP = /^\d{7,11}$/;
     if (this.encrytel) {
        if (!TEL_REGEXP.test(this.encrytel)) {
            callback(new Error(this.$t("bind.telErr")));
          } else {
            callback();
          }
     } else {
          if (!TEL_REGEXP.test(value)) {
          callback(new Error(this.$t("bind.telErr")));
          } else {
            callback();
          }
     }
   
  }
  },
 
  // var password = "";
  // var loginPassValue = "";
  // const validatorPassword = (val, value, callback) => {
  //   console.log(val);
  //   password = val;
  //   //只能是6位数字的--付款密码
  //   if (!val) {
  //     callback(new Error(this.$t("password.oldmsg")));
  //   }
  //   let reg = /^\d{6}$/;
  //   if (!reg.test(val)) {
  //     callback(new Error(this.$t("password.errFormat")));
  //   } else {
  //     callback();
  //   }
  // };
  
  //   const validatorAgain = (val) => {
  //     if (!val) {
  //       //只能是6位数字的--确认付款密码
  //       return t("password.newmsg");
  //     }
  //     let reg = /^\d{6}$/;
  //     if (!reg.test(val)) {
  //       return t("password.errFormat");
  //     } else if (val !== password.value) {
  //       return t("password.diffMsg");
  //     }
  //   };
  //   const loginPass = (val) => {
  //     //密码正则校验不能为中文，长度校验
  //     loginPassValue.value = val;
  //     if (!val) {
  //       return t("login.passplacehold");
  //     } else if (val.length < 6 || val.length > 18) {
  //       return t("login.passLength");
  //     }
  //     let reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
  //     if (reg.test(val)) {
  //       return t("login.passRrr");
  //     }
  //   };
  //   const loginPassAgain = (val) => {
  //     //确认登录密码
  //     if (!val) {
  //       return t("login.againpassmsg");
  //     } else if (val.length < 6 || val.length > 18) {
  //       return t("login.passLength");
  //     }
  //     let reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
  //     if (reg.test(val)) {
  //       return t("login.passRrr");
  //     } else if (val != loginPassValue.value) {
  //       return t("password.diffMsg");
  //     }
  //   };
  //   function onClickLeft() {
  //     history.back();
  //   }
  //   async function updataInfo() {
  //     try {
  //       let res = await getUserInfo();
  //       localStorage.removeItem("user");
  //       localStorage.setItem("user", JSON.stringify(res.data));
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  //   const emailVaildator = (val) => {
  //     //邮箱验证
  //     let bol = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
  //     if (!val) {
  //       return t("login.emailmsg");
  //     } else if (!bol.test(val)) {
  //       return t("login.emerrmsg");
  //     }
  //   };
  // const userInfo = JSON.parse(localStorage.getItem("user")); //用户信息
  // return {
  // validatorPassword,
  // validatePhone,
  // validatorAgain,
  // loginPass,
  // userInfo,
  // onClickLeft,
  // loginPassAgain,
  // updataInfo,
  // emailVaildator,
  // };
};
