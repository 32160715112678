import VueRouter from "vue-router";
import Vue from "vue";
import store from '../store'
Vue.use(VueRouter);
Vue.use(store);
const routes = [
  {
    path: "/home",
    name: "home",
    component: () => import("@/pages/home/index.vue"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/sellCard",
    name: "sellCard",
    component: () => import("@/pages/home/sellCard.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("@/pages/home/search.vue"),
  },
  {
    path: "/wallet", // 钱包
    name: "wallet",
    component: () => import("@/pages/wallet/index.vue"),
  },
  {
    path: "/login", // 登录
    name: "login",
    component: () => import("@/pages/login/index.vue"),
  },
  {
    path: "/order", // 订单
    name: "order",
    component: () => import("@/pages/order/list.vue"),
  },
  {
    path: "/order/detail", // 订单详情
    name: "order",
    component: () => import("@/pages/order/detail.vue"),
  },
  {
    path: "/person/:id",
    name: "person",
    component: () => import("@/pages/person/index.vue"),
  },
  {
    path: "/notic", // 通知
    name: "notic",
    component: () => import("@/pages/notic/index.vue"),
  },
  {
    path: "/noticDetail", // 通知
    name: "noticDetail",
    component: () => import("@/pages/notic/detail.vue"),
  },
  {
    path: "/rule", // 规则
    name: "rule",
    component: () => import("@/pages/rule/index.vue"),
  },
  {
    path: "/cententDetail", // 规则
    name: "cententDetail",
    component: () => import("@/pages/rule/detail.vue"),
  },
  {
    path: "/cententSec", // 规则
    name: "cententSec",
    component: () => import("@/pages/rule/sec.vue"),
  },
];
const router = new VueRouter({
  // mode: ''
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { y: 0 };
  },
});
router.beforeEach((to, form, next) => {
  if (to.path == "/") {
    router.replace("/home");
    // next(true)
  } else if (to.path == "/register"){
    if(to.query.code){
      sessionStorage.setItem('myCode',to.query.code)
      store.commit("onChangeRegister");
    }
    router.replace("/home");
  }
  next(true);
});

export default router;
