<template>
  <div>
    <el-button
      slot="suffix"
      type="primary"
      round
      class="send-btn"
      small
      :disabled="sendLoading"
      @click="onclick"
      >{{
        sendLoading ? num + $t("login.numCode") : $t("login.haveCode")
      }}</el-button
    >
  </div>
</template>
<script>
import { sendEmailCode } from "@/api/user";
export default {
  props: {
    email: String,
    type: String,
  },
  data() {
    return {
      sendLoading: false,
      num: 10,
      timer: null,
    };
  },
  methods: {
    onclick() {
      this.$emit("click");
    },
    count() {
      if (this.num > 1) {
        this.num--;
      } else {
        this.num = 10;
        this.sendLoading = false;
        window.clearInterval(this.timer);
      }
    },
    async onSend() {
      this.sendLoading = true;
      await sendEmailCode({ email: this.email, type: this.type })
        .then((res) => {
          if (res.code != 200) {
            this.sendLoading = false;
            return;
          } else {
            this.timer = setInterval(this.count, 1000);
            this.$message.success(this.$t("password.successSend"));
          }
        })
        .catch((err) => {
          this.sendLoading = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.send-btn {
  position: relative;
  top: 11px;
}
</style>
